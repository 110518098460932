<template>
    <v-bottom-navigation
        app
        background-color="secondary"
        color="primary"
        dark
        fixed
        grow
        :horizontal="horizontal"
    >
        <template v-for="(menuItem, index) in menu">
            <v-btn :key="`bottomNavigationItem_${index}`" :to="menuItem.to">
                <span>{{ menuItem.text }}</span>
                <AppVIcon :icon="menuItem.icon"/>
            </v-btn>
        </template>
    </v-bottom-navigation>
</template>

<script>
    import {
        VBottomNavigation,

        VBtn
    } from 'vuetify/lib'
    import AppVIcon from '@/components/vuetify/Icon'

    export default {
        name: 'BottomNavigation',

        components: {
            VBottomNavigation,

            VBtn,

            AppVIcon,
        },

        props: {
            menu: { type: Array, default: null },
        },

        data() {
            return {}
        },

        computed: {
            horizontal() {
                return !!this.$vuetify.breakpoint.smAndUp
            },
        },
    }
</script>
