<template>
    <v-navigation-drawer
        v-model="isShownNavigationDrawer"
        app
        color="secondary"
        dark
        :width="navigationDrawerWidth"
    >
        <slot/>
    </v-navigation-drawer>
</template>

<script>
    import { VNavigationDrawer } from 'vuetify/lib'

    export default {
        name: 'NavigationDrawer',

        components: {
            VNavigationDrawer,
        },

        props: {
            showNavigationDrawer: { type: Boolean, default: false },
        },

        data() {
            return {}
        },

        computed: {
            isShownNavigationDrawer: {
                get() {
                    return this.showNavigationDrawer
                },
                set(show) {
                    this.$emit('update:showNavigationDrawer', show)
                },
            },
            navigationDrawerWidth() {
                return '280px'
            },
        },
    }
</script>
