<template>
    <v-system-bar :color="systemBarColor" height="100" fixed status :style="systemBarStyle"/>
</template>

<script>
    import { VSystemBar } from 'vuetify/lib'

    import { mapState } from 'vuex'

    export default {
        name: 'SystemBar',

        components: {
            VSystemBar,
        },

        data() {
            return {}
        },

        computed: {
            ...mapState('ui', {
                appBar: state => state.appBar,
            }),

            systemBarColor() {
                return this.appBar.contextual.isEnabled ? 'black' : 'primary darken-1'
            },

            systemBarStyle() {
                return [
                    {
                        top: '-100px',
                    },
                ]
            }
        },
    }
</script>
