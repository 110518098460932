<template>
    <v-app-bar
        app
        :color="appBarComputed.color"
        :elevate-on-scroll="appBarComputed.elevateOnScroll"
        :light="appBarComputed.light"
        :dark="appBarComputed.dark"
        fixed
    >
        <v-btn
            v-if="appBarComputed.navigationIcon"
            :color="appBarComputed.dark ? 'primary' : ''"
            icon
            @click="appBarComputed.navigationIcon.click ? appBarComputed.navigationIcon.click() : null"
        >
            <AppVIcon :icon="appBarComputed.navigationIcon.icon"/>
        </v-btn>

        <v-toolbar-title
            v-if="appBarComputed.title"
            :class="{'primary--text': appBarComputed.dark}"
        >
            {{ appBarComputed.title }}
        </v-toolbar-title>

        <v-spacer/>
    </v-app-bar>
</template>

<script>
    import {
        VAppBar,
        VBtn,
        VSpacer,
        VToolbarTitle,
    } from 'vuetify/lib'
    import AppVIcon from '@/components/vuetify/Icon'

    import {
        cloneDeep as _cloneDeep,
    } from 'lodash'

    import { mapState, mapMutations } from 'vuex'

    export default {
        name: 'AppBar',

        components: {
            VAppBar,

            VBtn,

            AppVIcon,

            VSpacer,

            VToolbarTitle,
        },

        data() {
            return {}
        },

        computed: {
            ...mapState('ui', {
                appBar: state => state.appBar,
                navigationDrawer: state => state.navigationDrawer,
            }),

            appBarComputed() {
                let appBar = _cloneDeep(this.appBar)

                appBar.actionItems = appBar.contextual.isEnabled ? appBar.contextual.actionItems : appBar.actionItems
                appBar.color = appBar.contextual.isEnabled ? 'grey darken-4' : (appBar.color || 'secondary')
                appBar.elevateOnScroll = appBar.elevateOnScroll ? appBar.elevateOnScroll : true
                appBar.dark = appBar.dark ? appBar.dark : false
                appBar.light = appBar.light ? appBar.light : false
                appBar.title = appBar.contextual.isEnabled ? appBar.contextual.title : appBar.title

                if (appBar.navigationIcon) {
                    switch (appBar.navigationIcon.type) {
                        case 'nav':
                            if (this.$vuetify.breakpoint.mdAndDown) {
                                appBar.navigationIcon.icon = 'bars'
                                appBar.navigationIcon.click = () => this.setNavigationDrawer({ isShown: !this.navigationDrawer.isShown })
                            } else {
                                appBar.navigationIcon = null
                            }
                            break
                        case 'rootBack':
                            if (this.$vuetify.breakpoint.mdAndDown) {
                                appBar.navigationIcon.icon = 'arrowLeft'
                                appBar.navigationIcon.click = () => this.$router.back()
                            } else {
                                appBar.navigationIcon = null
                            }
                            break
                        case 'back':
                            appBar.navigationIcon.icon = 'arrowLeft'

                            if (!appBar.navigationIcon.click) {
                                appBar.navigationIcon.click = () => this.$router.back()
                            }
                            break
                    }
                }

                return appBar
            },
        },

        methods: {
            ...mapMutations({
                setNavigationDrawer: 'ui/setNavigationDrawer',
            }),
        },
    }
</script>
