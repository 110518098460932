<template>
    <v-app>
        <AppVSystemBar/>

        <AppVNavigationDrawer :showNavigationDrawer.sync="isShownNavigationDrawer">
            <v-list-item two-line class="primary">
                <v-list-item-content>
                    <v-list-item-title class="title secondary--text">
                        Пекарня Shen
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <template v-for="(menuGroup, menuGroupKey, menuGroupIndex) in navigationDrawerMenu">
                <template v-if="menuGroup.items.length">
                    <v-divider
                        v-if="menuGroupIndex > 0"
                        :key="`menuGroupDivider_${menuGroupIndex}`"
                    />

                    <v-list
                        :key="`menuGroup_${menuGroupIndex}`"
                        :two-line="menuGroupKey === 'phones'"
                    >
                        <template v-for="(menuItem, menuItemIndex) in menuGroup.items">
                            <v-list-item
                                :key="`menuGroupItem_${menuGroupIndex}_${menuItemIndex}`"
                                :href="menuItem.phone ? `tel:${menuItem.phone}` : null"
                                :to="menuItem.to ? menuItem.to : null"
                                @click="menuItem.click ? menuItem.click() : null"
                            >
                                <v-list-item-icon>
                                    <AppVIcon color="primary" :icon="menuItem.icon"/>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="primary--text">{{ menuItem.title }}</v-list-item-title>
                                    <v-list-item-subtitle class="primary--text">{{ menuItem.subtitle }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                </template>
            </template>

            <v-container class="white--text mt-12">
                <div class="mb-2" style="width: 63px;">
                    <v-img src="@/assets/logo_w.svg"/>
                </div>

                <h4>Пекарня <span>Shen</span></h4>
                <div class="body-2 grey--text">Печем и доставляем свежий хлеб в Тюмени</div>
            </v-container>
        </AppVNavigationDrawer>

        <AppVAppBar/>

        <v-content>
            <router-view/>
        </v-content>

        <AppVBottomNavigation v-if="isShownBottomNavigation" :menu="bottomNavigationMenu"/>
    </v-app>
</template>

<script>
    import {
        VApp,

        VContainer,

        VContent,

        VDivider,

        VImg,

        VList,
        VListItem,
        VListItemIcon,
        VListItemContent,
        VListItemTitle,
        VListItemSubtitle,
    } from 'vuetify/lib'
    import AppVAppBar from '@/components/vuetify/AppBar'
    import AppVBottomNavigation from '@/components/vuetify/BottomNavigation'
    import AppVIcon from '@/components/vuetify/Icon'
    import AppVNavigationDrawer from '@/components/vuetify/NavigationDrawer'
    import AppVSystemBar from '@/components/vuetify/SystemBar'

    import {
        forEach as _forEach,
    } from 'lodash'

    import { mapState, mapMutations } from 'vuex'

    import * as AuthService from '../services/auth.service'

    export default {
        name: 'purchase',

        components: {
            VApp,

            AppVAppBar,

            AppVBottomNavigation,

            VContainer,

            VContent,

            VDivider,

            AppVIcon,

            VImg,

            VList,
            VListItem,
            VListItemIcon,
            VListItemContent,
            VListItemTitle,
            VListItemSubtitle,

            AppVNavigationDrawer,

            AppVSystemBar,
        },

        data() {
            return {
                bottomNavigationMenu: [
                    {
                        to: { name: 'purchaseCatalog' },
                        icon: 'breadLoaf',
                        text: 'Каталог',
                    },
                    {
                        to: { name: 'purchaseOrder' },
                        icon: 'receipt',
                        text: 'Мой заказ',
                    },
                    {
                        to: { name: 'purchaseHistory' },
                        icon: 'history',
                        text: 'История',
                    },
                ],
            }
        },

        computed: {
            ...mapState('ui', {
                navigationDrawer: state => state.navigationDrawer,
            }),

            isShownNavigationDrawer: {
                get() {
                    return this.navigationDrawer.isShown
                },
                set(show) {
                    this.setNavigationDrawer({ isShown: show })
                },
            },

            isShownBottomNavigation() {
                let isShownBottomNavigation = false

                if (this.$vuetify.breakpoint.mdAndDown) {
                    _forEach(this.$route.matched, route => {
                        if (route.meta.isShownBottomNavigation) {
                            isShownBottomNavigation = true
                        }
                    })
                }

                return isShownBottomNavigation
            },

            navigationDrawerMenu() {
                let menu = {
                    purchase: {
                        name: 'Покупки',
                        items: [],
                    },
                    phones: {
                        name: 'Телефоны',
                        items: [],
                    },
                    system: {
                        name: 'Shen',
                        items: [],
                    },
                }

                _forEach(this.bottomNavigationMenu, menuItem => {
                    menu['purchase'].items.push({
                        icon: menuItem.icon,
                        title: menuItem.text,
                        to: menuItem.to,
                    })
                })

                menu['phones'].items.push({
                    icon: 'phone',
                    title: 'Менеджер',
                    subtitle: '+7 (3452) 600-605',
                    phone: '+73452600605',
                })

                menu['phones'].items.push({
                    icon: 'phone',
                    title: 'Водитель',
                    subtitle: '+79044734351',
                    phone: '+79044734351',
                })

                menu['system'].items.push({
                    icon: 'userShield',
                    title: 'Политика конфиденциальности',
                    to: { name: 'privacyPolicy' },
                })
                menu['system'].items.push({
                    icon: 'signOut',
                    title: 'Выход',
                    click: () => this.logout(),
                })

                return menu
            },
        },

        mounted() {
            this.isShownNavigationDrawer = this.$vuetify.breakpoint.lgAndUp
        },

        methods: {
            ...mapMutations({
                setNavigationDrawer: 'ui/setNavigationDrawer',
            }),

            logout() {
                AuthService
                    .makeLogout()
                    .catch(error => { AuthService.goToAuthFallback() })
            },
        },
    }
</script>

<style lang="scss" scoped>
    h1 {
        font-family: 'Oswald', sans-serif;
        font-size:   1.5rem;
    }
</style>
